import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../Services/Auth";

function Header(props) {
  let navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  return (
    <header className="app-header">
      <nav className="navbar navbar-expand-lg navbar-light">
        <ul className="navbar-nav w-100">
          <li className="nav-item d-block d-xl-none">
            <div
              className="nav-link sidebartoggler nav-icon-hover"
              id="headerCollapse"
              onClick={() => props?.setIsmobile(true)}
            >
              <i className="ti ti-menu-2"></i>
            </div>
          </li>
          <li className="nav-item d-block my-auto">
            <div className="h3 mb-0">{props?.title}</div>
          </li>
        </ul>
        <div
          className="navbar-collapse justify-content-end px-0"
          id="navbarNav"
        >
          <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
            <li className="nav-item dropdown">
              <div
                className="nav-link nav-icon-hover cursor-pointer"
                id="drop2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                rel="noreferrer"
              >
                <img
                  src="../assets/images/profile/user-1.jpg"
                  alt=""
                  width="35"
                  height="35"
                  className="rounded-circle"
                />
              </div>
              <div
                className="dropdown-menu dropdown-menu-end dropdown-menu-animate-up"
                aria-labelledby="drop2"
              >
                <div className="message-body">
                  <div
                    onClick={() => {
                      navigate("/login");
                      logout();
                    }}
                    className="btn btn-outline-primary mx-3 mt-2 d-block"
                  >
                    Logout
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <hr></hr>
    </header>
  );
}

export default Header;
