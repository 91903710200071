import { LOGIN, LOGOUT, GET_USER_DATA } from "./types";

const reducer = (state, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loading: false,
        payload: action?.payload?.data?.data,
      };
    case LOGOUT:
      return {
        ...state,
        loading: false,
      };
    case GET_USER_DATA:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
