// import fileDownload from "js-file-download";
import API from "./ApiConfig";

function buildResponse(response) {
  return response;
}

// function buildResponseDownload(response, fileName, ext) {
//   fileDownload(response.data, `${fileName}.${ext}`);
// }

const token = localStorage.getItem("accessToken");

API.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const ApiWithAuth = {
  setToken(token) {
    API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },
  getDataWithAuth: async (url, parameter) => {
    return API.get(`${url}`, {
      params: parameter,
    })
      .then((response) => buildResponse(response))
      .catch((err) => buildResponse(err.response ?? null));
  },
  postDataWithAuth: async (url, body) => {
    return API.post(`${url}`, body)
      .then((response) => buildResponse(response))
      .catch((err) => buildResponse(err.response ?? null));
  },
  postDataFormWithAuth: async (url, body) => {
    return API.post(`${url}`, body, {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => buildResponse(response))
      .catch((err) => buildResponse(err.response ?? null));
  },
  putDataWithAuth: async (url, body) => {
    return API.put(`${url}`, body)
      .then((response) => buildResponse(response))
      .catch((err) => buildResponse(err.response ?? null));
  },
  deleteDataWithAuth: async (url) => {
    return API.delete(`${url}`)
      .then((response) => buildResponse(response))
      .catch((err) => buildResponse(err.response ?? null));
  },
  // downloadDataWithAuth: async (url, parameter, fileName, ext) => {
  //   return API.get(url, {
  //     method: "GET",
  //     responseType: "blob",
  //     params: parameter,
  //   })
  //     .then((response) => buildResponseDownload(response, fileName, ext))
  //     .catch((err) => buildResponse(err.response ?? null));
  // },
};

export default ApiWithAuth;
