import React, { useReducer } from "react";
import { toast } from "react-toastify";
import ApiWithAuth from "../../Config/Api/ApiWithAuth";
import AssetContext from "./index";
import AssetReducer from "./reducer";
import { GET_ALL, GET, ADD, UPDATE, DELETE } from "./types";

const MasterState = ({ children }) => {
  const initialState = {
    data: [],
    loading: true,
  };

  const [state, dispatch] = useReducer(AssetReducer, initialState);

  const getAllMaster = async (path, parameter) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth(`/${path}`, parameter);
      if (data?.status===200) {
        dispatch({ type: GET_ALL, payload: data?.data });
      } else {
        toast.error(JSON.stringify(data?.data?.message));
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const getMaster = async (path, id) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth(`/${path}/${id}`);
      if (data?.status===200) {
        dispatch({ type: GET, payload: data?.data });
      } else {
        toast.error(JSON.stringify(data?.data?.message));
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const addMaster = async (path, body) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        `/${path}`,
        JSON.stringify(body)
      );
      if (data?.status===200) {
        dispatch({ type: ADD });
      } else {
        toast.error(JSON.stringify(data?.data?.message));
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const addMasterForm = async (path, body) => {
    try {
      const data = await ApiWithAuth.postDataFormWithAuth(
        `/${path}`,
        body
      );

      if (data?.status===200) {
        dispatch({ type: ADD });
      } else {
        toast.error(JSON.stringify(data?.data?.message));
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const updateMaster = async (path, id, body) => {
    try {
      const data = await ApiWithAuth.putDataWithAuth(
        `/${path}/${id}`,
        JSON.stringify(body)
      );
      if (data?.status===200) {
        dispatch({ type: UPDATE });
        toast.success("Updated Data Success");
      } else {
        toast.error(JSON.stringify(data?.data?.message));
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const deleteMaster = async (path, id) => {
    try {
      const data = await ApiWithAuth.deleteDataWithAuth(`/${path}/${id}`);
      if (data?.status===200) {
        dispatch({ type: DELETE });
        toast.success("Deleted Data Success");
      } else {
        toast.error(JSON.stringify(data?.data?.message));
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const { data, loading } = state;

  return (
    <AssetContext.Provider
      value={{
        data,
        loading,
        getAllMaster,
        getMaster,
        addMaster,
        addMasterForm,
        updateMaster,
        deleteMaster,
      }}
    >
      {children}
    </AssetContext.Provider>
  );
};

export default MasterState;
