import React, { useReducer } from "react";
import { toast } from "react-toastify";
import ApiWithoutAuth from "../../Config/Api/ApiWithoutAuth";
import AuthContext from "./index";
import AuthReducer from "./reducer";
import { LOGIN, LOGOUT, GET_USER_DATA } from "./types";

const AuthState = ({ children }) => {
  const initialState = {
    loading: false,
    payload: {},
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const login = async (email, password) => {
    try {
      const body = {
        email: email,
        password: password,
      };
      const data = await ApiWithoutAuth.postDataWithoutAuth(
        `/login`,
        JSON.stringify(body)
      );
      const response = data?.data;
      if (data?.status===200) {
        localStorage.setItem(
          "accessToken",
          response?.token?.access_token
        );
        localStorage.setItem("expiresIn", response?.token?.expires_in);
        localStorage.setItem("id", response?.id);
        localStorage.setItem("name", response?.name);
        localStorage.setItem("email", response?.email);
        localStorage.setItem("role", response?.role);
      } else {
        toast.error(JSON.stringify(response?.message));
      }
      dispatch({ type: LOGIN, payload: data });
      return response;
    } catch (err) {
      console.error(err.message);
    }
  };

  const logout = async () => {
    try {
      dispatch({ type: LOGOUT });
      localStorage.clear();
    } catch (err) {
      console.error(err.message);
    }
  };

  const getUser = async () => {
    try {
      const data = await ApiWithoutAuth.getDataWithoutAuth(
        "/tokens/user/" + localStorage.getItem("_id")
      );
      localStorage.setItem("user", data?.data);
      dispatch({ type: GET_USER_DATA });
    } catch (err) {
      console.error(err.message);
    }
  };

  const { loading, payload } = state;

  return (
    <AuthContext.Provider
      value={{
        loading,
        payload,
        login,
        logout,
        getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;
