import { Link } from "react-router-dom";
import PSNLogo from "../../Assets/Images/logo-hitam.png";

function Sidebar(params) {
  const pathname = window.location.pathname;

  return (
    <aside className="left-sidebar">
      <div>
        <div className="brand-logo d-flex align-items-center justify-content-between">
          <Link to="/" className="text-nowrap logo-img w-100 text-center">
            <img src={PSNLogo} width="180" alt="" />
          </Link>
          <div
            className="close-btn d-xl-none d-block sidebartoggler cursor-pointer"
            id="sidebarCollapse"
            onClick={() => params?.setIsmobile(false)}
          >
            <i className="ti ti-x fs-8"></i>
          </div>
        </div>
        <nav className="sidebar-nav scroll-sidebar" data-simplebar="">
          <ul id="sidebarnav">
            <li className="nav-small-cap">
              <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
              <span className="hide-menu">Master</span>
            </li>
            {localStorage.getItem("role") === "Superadmin" && (
              <li
                className={
                  "sidebar-item " +
                  (pathname === "/master/user" ? "selected" : "")
                }
              >
                <Link
                  className={
                    "sidebar-link " +
                    (pathname === "/master/user" ? "active" : "")
                  }
                  to="/master/user"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-users"></i>
                  </span>
                  <span className="hide-menu">Users</span>
                </Link>
              </li>
            )}
            <li
              className={
                "sidebar-item " +
                (pathname === "/master/news" ? "selected" : "")
              }
            >
              <Link
                className={
                  "sidebar-link " +
                  (pathname === "/master/news" ? "active" : "")
                }
                to="/master/news"
                aria-expanded="false"
              >
                <span>
                  <i className="ti ti-news"></i>
                </span>
                <span className="hide-menu">Berita</span>
              </Link>
            </li>
            <li
              className={
                "sidebar-item " +
                (pathname === "/master/vacancy" ? "selected" : "")
              }
            >
              <Link
                className={
                  "sidebar-link " +
                  (pathname === "/master/vacancy" ? "active" : "")
                }
                to="/master/vacancy"
                aria-expanded="false"
              >
                <span>
                  <i className="ti ti-user-search"></i>
                </span>
                <span className="hide-menu">Lowongan Pekerjaan</span>
              </Link>
            </li>
            <li className="nav-small-cap">
              <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
              <span className="hide-menu">Proses Lowongan</span>
            </li>
            <li className="sidebar-item">
              <Link
                className={
                  "sidebar-link " + (pathname === "/vacancy/apply" ? "active" : "")
                }
                to="/vacancy/apply"
                aria-expanded="false"
              >
                <span>
                  <i className="ti ti-calculator"></i>
                </span>
                <span className="hide-menu">Proses Lowongan</span>
              </Link>
            </li>
          </ul>
          <div className="unlimited-access hide-menu bg-light-primary position-relative mb-7 mt-5 rounded">
            <div className="d-flex">
              <div className="unlimited-access-title me-3">
                <h6 className="fw-semibold fs-4 mb-6 text-dark w-85">
                  Main Website
                </h6>
                <a
                  href="https://psn.com/"
                  target="_blank"
                  className="btn btn-primary fs-2 fw-semibold lh-sm"
                  rel="noreferrer"
                >
                  Go
                </a>
              </div>
              <div className="unlimited-access-img">
                <img
                  src="../assets/images/backgrounds/rocket.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </aside>
  );
}

export default Sidebar;
