import { useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import Table from "./Table";
import Layout from "../../Layouts";
import AdminContext from "../../Services/Admin";


function VacancyApplyPage(params) {
  const { loading, getAllMaster } =
    useContext(AdminContext);
  const [data, setData] = useState([]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "vacancy.type",
        header: "Type",
      },
      {
        accessorKey: "vacancy.position",
        header: "Position",
      },
      {
        accessorKey: "vacancy.department",
        header: "Department",
      },
      {
        accessorKey: "vacancy.placement",
        header: "Placement",
      },
      {
        accessorKey: "document_file",
        header: "Document File",
        Cell: ({ cell }) =>
          cell && (
            <a
              href={process.env.REACT_APP_FILE_URL + cell.getValue()}
              target="_blank"
              rel="noreferrer"
            >
              Download File
            </a>
          ),
      },
      {
        accessorKey: "updated_at",
        header: "Apply At",
        Cell: ({ cell }) => moment(cell.getValue()).locale("id").format("LLL"),
      },
    ],
    []
  );

  const getData = async () => {
    const newData = await getAllMaster("vacancy-apply");
    if (newData) {
      setData(newData);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout title="Data Vacancy">
      <Table
        columns={columns}
        data={data}
        getData={getData}
        getAllAction={getAllMaster}
        loading={loading}
      />
    </Layout>
  );
}

export default VacancyApplyPage;
