import PSNLogo from "../../Assets/Images/logo-hitam.png";
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import AuthContext from "../../Services/Auth";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import ApiWithAuth from "../../Config/Api/ApiWithAuth";

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

function LoginPage() {
  const { loading, login } = useContext(AuthContext);
  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (values) => {
    let response = await login(values.email, values.password);
    if (response) {
      console.log(response)
      setTimeout(() => {
        ApiWithAuth.setToken(response?.token?.access_token);
        navigate("/master/news");
      }, 500);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("role")) {
      navigate("/master/news");
    } else {
      navigate("/login");
    }
  }, [])

  return (
    <div
      class="page-wrapper"
      id="main-wrapper"
      data-layout="vertical"
      data-navbarbg="skin6"
      data-sidebartype="full"
      data-sidebar-position="fixed"
      data-header-position="fixed"
    >
      <div class="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        <div class="d-flex align-items-center justify-content-center w-100">
          <div class="row justify-content-center w-100">
            <div class="col-md-8 col-lg-6 col-xxl-3">
              <div class="card mb-0">
                <div class="card-body">
                  <a
                    href="./index.html"
                    class="text-nowrap logo-img text-center d-block py-3 w-100"
                  >
                    <img src={PSNLogo} width="180" alt="" />
                  </a>
                  <p class="text-center">Login to Admin Panel</p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        {...register("email")}
                        placeholder="Enter your email"
                      />
                      <small className="text-danger">
                        {errors.email?.message}
                      </small>
                    </div>
                    <div class="mb-4">
                      <label for="exampleInputPassword1" class="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        class="form-control"
                        id="exampleInputPassword1"
                        {...register("password")}
                        placeholder="**************"
                      />
                      <small className="text-danger">
                        {errors.password?.message}
                      </small>
                    </div>
                    <button
                      disabled={loading}
                      type="submit"
                      class="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2"
                    >
                      Masuk
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
