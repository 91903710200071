import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AuthState from "../Services/Auth/state.js";
import AdminState from "../Services/Admin/state.js";
import PrivateRoute from "./PrivateRoutes";
import UserPage from "../Pages/Users";
import LoginPage from "../Pages/Auth/Login";
import NewsPage from "../Pages/News/index.js";
import VacancyPage from "../Pages/Vacancy/index.js";
import VacancyApplyPage from "../Pages/VacancyApply/index.js";

const AppRoutes = () => {
  return (
    <AuthState>
      <AdminState>
        <BrowserRouter>
          <Routes>
            <Route>
              <Route path="/" element={<LoginPage />} />
              <Route path="login" element={<LoginPage />} />

              <Route element={<PrivateRoute />}>
                <Route path="master">
                  <Route path="user" element={<UserPage />} />
                  <Route path="news" element={<NewsPage />} />
                  <Route path="vacancy" element={<VacancyPage />} />
                </Route>
                <Route path="vacancy/apply" element={<VacancyApplyPage />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </AdminState>
    </AuthState>
  );
};

export default AppRoutes;
