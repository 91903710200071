import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useContext, useEffect, useMemo, useState } from "react";
import { Badge } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import Select from "react-select";
import Table from "./Table";
import Layout from "../../Layouts";
import AdminContext from "../../Services/Admin";

const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    roles: yup.string().required(),
    password: yup.string().min(5).required(),
    re_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

function UsersPage(params) {
  const { loading, getAllMaster, addMaster, addMasterForm } =
    useContext(AdminContext);
  const [data, setData] = useState([]);
  const dataRoles = [
    {
      label: "Superadmin",
      value: "Superadmin",
    },
    {
      label: "Admin",
      value: "Admin",
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Full Name",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "roles",
        header: "Role",
        Cell: ({ cell }) => cell.getValue()?.[0]?.name,
      },
      {
        accessorKey: "updated_at",
        header: "Last Updated",
        Cell: ({ cell }) => moment(cell.getValue()).locale("id").format("LLL"),
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell }) => {
          let status = "Non Active";
          if (cell.getValue()) {
            status = "Active";
          }
          return (
            <Badge bg={status === "Active" ? "success" : "danger"}>
              {status}
            </Badge>
          );
        },
      },
    ],
    []
  );

  const bodyForm = () => {
    return (
      <>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Full Name
          </label>
          <input
            type="text"
            id="name"
            className="form-control"
            {...register("name")}
            placeholder="Enter your full name"
          />
          <small className="text-danger">{errors.name?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            id="email"
            className="form-control"
            {...register("email")}
            placeholder="Enter your email"
          />
          <small className="text-danger">{errors.email?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            id="password"
            className="form-control"
            {...register("password")}
            placeholder="Enter your password"
          />
          <small className="text-danger">{errors.password?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="re_password" className="form-label">
            Retype Password
          </label>
          <input
            type="password"
            id="re_password"
            className="form-control"
            {...register("re_password")}
            placeholder="Retype your password"
          />
          <small className="text-danger">{errors.re_password?.message}</small>
        </div>

        <div className="mb-3">
          <label htmlFor="roles" className="form-label">
            Role
          </label>
          <Controller
            control={control}
            name="roles"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                ref={ref}
                value={dataRoles.find((c) => c.value === value)}
                onChange={(val) => {
                  onChange(val.value);
                }}
                options={dataRoles}
                className="form-control p-0 border-0"
              />
            )}
          />
          <small className="text-danger">{errors.roles?.message}</small>
        </div>

        <div className="mb-3">
          <label htmlFor="status" className="form-label">
            Status
          </label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="status"
              value="true"
              style={{ minWidth: "50px" }}
              {...register("status")}
            />
            <label className="form-check-label small ps-2" htmlFor="status">
              Active
            </label>
          </div>
          <small className="text-danger">{errors.status?.message}</small>
        </div>
      </>
    );
  };

  const getData = async () => {
    const newData = await getAllMaster("user", {
      role: ["Superadmin", "Admin"],
    });
    if (newData) {
      setData(newData);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout title="Data Users">
      <Table
        columns={columns}
        data={data}
        getData={getData}
        getAllAction={getAllMaster}
        addAction={addMaster}
        addActionForm={addMasterForm}
        bodyForm={bodyForm}
        handleSubmit={handleSubmit}
        reset={reset}
        loading={loading}
        manualPagination
      />
    </Layout>
  );
}

export default UsersPage;
