import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useContext, useEffect, useMemo, useState } from "react";
import { Badge } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import Select from "react-select";
import Table from "./Table";
import Layout from "../../Layouts";
import AdminContext from "../../Services/Admin";
import WYSIWYGEditor from "../../Components/Wysiwyg";

const schema = yup
  .object({
    type: yup.string().required(),
    position: yup.string().required(),
    department: yup.string().required(),
    placement: yup.string().required(),
    description: yup.string().required(),
  })
  .required();

const defaultValues = {
  type: "Fresh Graduated",
  position: "",
  department: "",
  placement: "",
  description: "",
  status: "",
};

function VacancyPage(params) {
  const { loading, getAllMaster, addMaster, addMasterForm } =
    useContext(AdminContext);
  const [data, setData] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues });
  const dataTypes = [
    {
      label: "Fresh Graduated",
      value: "Fresh Graduated",
    },
    {
      label: "Experienced",
      value: "Experienced",
    },
    {
      label: "Intership",
      value: "Intership",
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: "type",
        header: "Type",
      },
      {
        accessorKey: "position",
        header: "Position",
      },
      {
        accessorKey: "department",
        header: "Department",
      },
      {
        accessorKey: "placement",
        header: "Placement",
      },
      {
        accessorKey: "updated_at",
        header: "Last Updated",
        Cell: ({ cell }) => moment(cell.getValue()).locale("id").format("LLL"),
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell }) => {
          let status = "Non Active";
          if (cell.getValue()) {
            status = "Active";
          }
          return (
            <Badge bg={status === "Active" ? "success" : "danger"}>
              {status}
            </Badge>
          );
        },
      },
    ],
    []
  );

  const bodyForm = () => {
    return (
      <>
        <div className="mb-3">
          <label htmlFor="roles" className="form-label">
            Role
          </label>
          <Controller
            control={control}
            name="roles"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                ref={ref}
                value={dataTypes.find((c) => c.value === value)}
                onChange={(val) => {
                  onChange(val.value);
                }}
                options={dataTypes}
                className="form-control p-0 border-0"
              />
            )}
          />
          <small className="text-danger">{errors.roles?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="position" className="form-label">
            Position
          </label>
          <input
            type="text"
            id="position"
            className="form-control"
            {...register("position")}
            placeholder="Enter vacancy position"
          />
          <small className="text-danger">{errors.position?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="department" className="form-label">
            Department
          </label>
          <input
            type="text"
            id="department"
            className="form-control"
            {...register("department")}
            placeholder="Enter vacancy department"
          />
          <small className="text-danger">{errors.department?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="placement" className="form-label">
            Placement
          </label>
          <input
            type="text"
            id="placement"
            className="form-control"
            {...register("placement")}
            placeholder="Enter vacancy placement"
          />
          <small className="text-danger">{errors.placement?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <WYSIWYGEditor onChange={field.onChange} value={field.value} />
            )}
          />
          <small className="text-danger">{errors.description?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="status" className="form-label">
            Status
          </label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="status"
              value="true"
              style={{ minWidth: "50px" }}
              {...register("status")}
            />
            <label className="form-check-label small ps-2" htmlFor="status">
              Active
            </label>
          </div>
          <small className="text-danger">{errors.status?.message}</small>
        </div>
      </>
    );
  };

  const getData = async () => {
    const newData = await getAllMaster("vacancy");
    if (newData) {
      setData(newData);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout title="Data Vacancy">
      <Table
        columns={columns}
        data={data}
        getData={getData}
        getAllAction={getAllMaster}
        addAction={addMaster}
        addActionForm={addMasterForm}
        bodyForm={bodyForm}
        handleSubmit={handleSubmit}
        reset={reset}
        loading={loading}
        defaultValues={defaultValues}
      />
    </Layout>
  );
}

export default VacancyPage;
