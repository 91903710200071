import Header from "./Components/Header";
import Sidebar from "./Components/Sidebar";
import Footer from "./Components/Footer";
import { useEffect, useState } from "react";

function Layout(props) {
  const [isMobile, setIsmobile] = useState(false);

  return (
    <div
      className={"page-wrapper " + (isMobile && "show-sidebar")}
      id="main-wrapper"
      data-layout="vertical"
      data-sidebartype="full"
      data-sidebar-position="fixed"
      data-header-position="fixed"
    >
      <Sidebar setIsmobile={setIsmobile} />
      <div className="body-wrapper">
        <Header title={props?.title} setIsmobile={setIsmobile}/>
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">{props?.children}</div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Layout;
