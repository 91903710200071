import MaterialReactTable from "material-react-table";

function Table(params) {

  return (
    <>
      <MaterialReactTable
        muiTablePaperProps={{
          sx: {
            boxShadow: "none",
            borderRadius: "1rem",
          },
        }}
        muiTopToolbarProps={{
          sx: {
            borderRadius: "1rem",
          },
        }}
        muiBottomToolbarProps={{
          sx: {
            borderRadius: "1rem",
          },
        }}
        state={{
          isLoading: params.loading,
        }}
        getRowId={(originalRow) => originalRow.id}
        columns={params.columns}
        data={params.data}
      />
    </>
  );
}

export default Table;
